import styled from 'styled-components'
import {device} from '../../../theme'

export const ServicesWrapper = styled.section `

background:#f2f2f2;
margin-top:20px;    
padding-top: 60px;
padding-bottom: 30px;
.col-pt{padding-top:100px;
 p{font-size:18px;}
}
  h3 {span {
    color: #f36d21;
     margin-bottom:10px;}
  }
    . rowspace{padding-bottom:30px;}
    img{
        width:75px;
        height:75px;
        position:absolute;
        right:30px;
        bottom:40px;
    }
    .arrow1,.arrow2, .arrow3, .arrow4{
        &:before{
            background: url(../../../../curve-arrow.svg) no-repeat;
            position: absolute;
            content: "";
            width: 86px;
            height: 252px;
            opacity:0.4;
         }
    }
    .arrow1{  
        &:before{
            top: -155px;
            right: -81px;
            transform: rotate3d(1, 1, 0,180Deg);
         }
      }
      .arrow2{  
        &:before{
            top: 74px;
             right: -58px;
            transform: rotate3d(0,1,0,180Deg);
         }
      }
      .arrow3{  
        &:before{
            top: 52px;
            left: -101px;
            transform: rotate3d(-16,16,0,180Deg);
         }
      }
      .arrow4{  
        &:before{
            top: -158px;
            left: -57px;
            transform: rotate3d(-94,1,0,180Deg);
    
         }
      }
   
    @media ${device.medium}{
        padding-top: 72px;
        padding-bottom: 80px;
        .col-pt{padding-top:10px;}
        .arrow1,.arrow2, .arrow3, .arrow4{
            &:before{content:none;}
    }
    @media ${device.small}{
        padding-top: 30px;
        padding-bottom: 30px;
        .col-pt{padding-top:10px;}
        .rowspace{padding-bottom:0px !important; margin-bottom:0 !important}
        .arrow1,.arrow2, .arrow3, .arrow4{
            &:before{content:none;}
    }
`;
export const DesignTool = styled.div `
   
h3 {
            font-size: 25px;
            font-weight: 500;
            padding: 54px;
        }
        margin-bottom:30px;
        width:100%;
        height:150px;
        box-shadow: 0px 2px 80px 0px rgba(0,0,0,0.07);
        border-radius: 10px 10px 10px 10px;
        background:#ffff;
        &:hover{
        span{
            width: 100px;
            height: 100px;
            }
        }
        span{
            width: 80px;
            height: 80px;
            background-color: #f36d21;
            margin-left: 100px;
            position: absolute;
            bottom: 30px;
            right: 15px;
            opacity: 0.2;
            border-radius: 100% 0 0 0;
        
        }
@media ${device.medium}{
    padding-top: 72px;
    padding-bottom: 80px;
    h3 {
        padding:0;
        padding-left: 40px;
    }  
}
@media ${device.small}{
    padding-top: 52px;
    padding-bottom: 57px;
    h3 {
        padding:0;
        padding-left: 40px;
    }  
}
`;