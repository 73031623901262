import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import AboutArea from '../containers/about-us/about-area'
import ServicesArea from '../containers/about-us/services-area'
import ResourcesArea from '../containers/about-us/resources-area'
import SolutionsArea from '../containers/about-us/solutions-area'
import FunFactArea from '../containers/global/funfact-area/section-one'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import ClientsArea from '../containers/global/clients-area'
import ContactArea from '../containers/global/contact-area/contact-two'

const AboutPage = ({ pageContext ,location }) => (
  <Layout location={location}>
    <SEO title="About Us" />
    <Header transparent/>
    <main className="site-wrapper-reveal">
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="About Us"
    />
      <AboutArea />
      <ServicesArea />
      <SolutionsArea />
      <TestimonialArea />
    </main>
    <Footer />
  </Layout>
)

export default AboutPage
