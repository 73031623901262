import styled, {css} from 'styled-components';
import { device } from '../../../theme'

export const BrandLogoWrap = styled.div `
    position: relative;
    a{
        display: block;
    }
    .brand-logo__image{
        width:250px;
        transition: all .4s linear;
    }
    
    ${props => props.layout === 1 && css `
        .brand-logo__image{
            
            &-hover{
                visibility: hidden;
                opacity: 0;
                transition: all .4s linear;
                position: absolute;
                top: 0;
                left: 0;
                width: 250px;
            }
        }
        &:hover{
            .brand-logo__image{
                visibility: hidden;
                opacity: 0;
                &-hover{
                    opacity: 1;
                    visibility: visible;
                    transform: none;
                }
            }
        }
    `}
    ${props => props.layout === 2 && css `
        .brand-logo__image{
            &-hover{
                visibility: hidden;
                opacity: 0;
                transition: all .4s linear;
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
            }
        }
        &:hover{
            .brand-logo__image{
                visibility: hidden;
                opacity: 0;
                &-hover{
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%, 0);
                }
            }
        }
    `}

    ${props => props.layout === 3 && css `
        .brand-logo__image{
            opacity: .4;
            &:hover{
                opacity: 1;
            }
        }
    `}
    
    ${props => props.layout === 4 && css `
        .brand-logo__image{
            opacity: 1;
            &:hover{
                opacity: .4;
            }
        }
    `}
`;  