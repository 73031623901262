import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import SectionTitle from '../../../../components/ui/section-title'
import SwiperSlider from '../../../../components/ui/swiper'
import Testimonial from '../../../../components/testimonial/layout-one'
import { TestimonialWrapper } from './section.style'


const TestimonialSection = ({ sectionTitleStyle, slider, sliderStyle }) => {
    const testimonialData = useStaticQuery(graphql`
    query GlobalTestimonialQuery {
        allTestimonialsJson {
            edges {
              node {
                id
                author_name
                author_designation
                rating
                review
                author_image {
                  childImageSharp {
                    fixed(width: 90, height: 90, quality: 100){
                        ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
        }
    }
    `);
    // const testimonialSecData = testimonialData.appointmentJson;
    const testimonials = testimonialData.allTestimonialsJson.edges;
    return (
        <TestimonialWrapper>
            <Container>
                {/* <Row className="mb-4 rowspace ">
                    <Col lg={5} className="col-pt">
                        <h3 className="mb-4"> Management <span>Team</span></h3>
                    </Col>
                </Row> */}
                <Row>
                <Col lg={6}>
                        <Row className="subprofile">
                            <Col lg={3}><img src={"../../../../madhu.png"} alt="ideasimg" className="circle-img"></img></Col>
                            <Col lg={9} className="mb-3 pl-lg-0"><p className="my-0 text-left mb-1" ><strong>Madhu Babu Gadipati</strong><small>- Co-Founder & CTO</small></p>
                                <p className="my-0 text-left">An energetic CEO with 20+ years of experience and proven industry experience in Regulated crypto exchange, Blockchain, Gov-Tech, Investment banking and SAAS domains.
                                Incubating ideas and delivering software solutions for crypto space from Custodians, Non- Custodians, AML, KYC/KYB, KYT, FX, Debit Cards, Crypto Exchanges, Security, Compliance, Trade execution, Reconciliation, Settlements, LP connectivity, Defi, NFT, Smart Contracts.</p>
                            </Col>
                        </Row>
                        {/* <Row className="subprofile">
                            <Col lg={3}><img src={"../../../../subprofile1.png"} alt="ideasimg" className="circle-img text-center"></img></Col>
                            <Col lg={9} className="pl-lg-0"><p className="my-0 mb-1 text-left" ><strong>Venkata Durgarao.P</strong><small>- Head of Delivery</small></p>
                                <p className="my-0 text-left">Durgarao is responsible for the delivery of SmartCursors modules, work closely with teams to provide reviews & feedbacks, resolve technical impediments, and continuously looking for ways to improve team.</p>
                            </Col>
                        </Row> */}

                    </Col>
                    {/* <Col lg={4}>
                        <p className="pt-3">
                        An energetic CEO with 20+ years of experience and proven industry experience in Regulated crypto exchange, Blockchain, Gov-Tech, Investment banking and SAAS domains.
                       </p>
                       <p className="pt-3">Incubating ideas and delivering software solutions for crypto space from Custodians, Non- Custodians, AML, KYC/KYB, KYT, FX, Debit Cards, Crypto Exchanges, Security, Compliance, Trade execution, Reconciliation, Settlements, LP connectivity, Defi, NFT, Smart Contracts.</p>
                        <p className="my-0" ><strong>Madhu Babu Gadipati</strong><br></br><small>- Co-Founder & CTO</small></p></Col>

                    <Col lg={4}>
                        <img src={"../../../../madhu.png"} alt="ideasimg" className="align-right main-profile"></img>
                    </Col> */}
                    <Col lg={6}>
                        <Row className="subprofile">
                            <Col lg={3}><img src={"../../../../GVKrishna.png"} alt="ideasimg" className="circle-img"></img></Col>
                            <Col lg={9} className="mb-3 pl-lg-0"><p className="my-0 text-left mb-1" ><strong>G.V. Krishna</strong><small>- Director</small></p>
                                <p className="my-0 text-left">Mr G.V Kirshna responsible for guiding a business's strategic direction having more than two decades of experience. He maintains positive client relationships and drive new business acquisitions and develop and implement organizational goals, procedures, and policies. </p>
                            </Col>
                        </Row>
                        {/* <Row className="subprofile">
                            <Col lg={3}><img src={"../../../../subprofile1.png"} alt="ideasimg" className="circle-img text-center"></img></Col>
                            <Col lg={9} className="pl-lg-0"><p className="my-0 mb-1 text-left" ><strong>Venkata Durgarao.P</strong><small>- Head of Delivery</small></p>
                                <p className="my-0 text-left">Durgarao is responsible for the delivery of SmartCursors modules, work closely with teams to provide reviews & feedbacks, resolve technical impediments, and continuously looking for ways to improve team.</p>
                            </Col>
                        </Row> */}

                    </Col>
                </Row>
            </Container>
        </TestimonialWrapper>
    )
}

TestimonialSection.propTypes = {
    sliderStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    slider: PropTypes.object
}

TestimonialSection.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    slider: {
        slidesPerView: 2,
        loop: true,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            992: {
                slidesPerView: 2
            }
        }
    },
    sliderStyle: {
        pagination: {
            mt: '28px'
        }
    },
}

export default TestimonialSection;