import styled from 'styled-components'
import {device} from '../../../../theme'

export const TestimonialWrapper = styled.section `
    padding-top: 30px;
    padding-bottom: 0px;
    background-color: #F8F8F8;
    h3 span {
        color: #f36d21;
        margin-bottom: 10px;}
    p{
        font-style:italic;
        line-height:1.5em;
        font-weight:500;
        color: rgba(22,20,62,0.75);
        font-size: 14px;
        text-align:right;
         margin-top:0px;
    }
    .ideasimg
        {
        width: 60px;
        position: absolute;
        right: 25px;
        top: 0px;
    }
    .main-profile{
        width:100%;
    }
    .circle-img
    {
        border-radius:50%;
    }
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 80px;
        .subprofile{
            .pl-0{padding-left:15px;}
        }
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
        p{
            text-align:left;margin-top:0;
        }
        .subprofile{
            .pl-0{padding-left:15px !important;}
            img{margin-bottom:10px; margin-top:10px}
        }
        .rowspace{margin-bottom:0 !important;}
        span{opacity:0}
        .main-profile, .ideasimg{display:none;}
    }
`;