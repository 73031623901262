import styled from "styled-components";

export const SectionWrap = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;
    .client-style{display:flex;
     
   
    }
    .client-style img{width:250px;}
    .client-style{display:flex;align-items:center;}
    .mr-ml{margin-left:10px;margin-right:10px;}
`;

export const ClientLogoWrap = styled.div`
    align-self: center;
    text-align: center;
    position: relative;
    // .client-style{display:flex;align-items:center;}
    .client-style img{width:250px;}
    // .mr-ml{margin-left:10px;margin-right:10px;}
    
`;