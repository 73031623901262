import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import ClientLogo from '../../../components/ui/client-logo'
import SwiperSlider from '../../../components/ui/swiper'
import SectionTitle from '../../../components/ui/section-title'
import { SectionWrap, ClientLogoWrap } from './style'
import Img from "gatsby-image"

const ClientsArea = (props) => {
    const partnersQueryData = useStaticQuery(graphql`
        query AboutPartnersDataQuery{
            allPartnersJson {
                edges {
                  node {
                    id
                    path
                    image {
                      childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_tracedSVG
                          presentationWidth
                          presentationHeight
                          aspectRatio
                        }
                      }
                    }
                    hover_image {
                      childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                            aspectRatio
                        }
                      }
                    }
                  }
                }
            }
        }
    `);
    const partnersData = partnersQueryData.allPartnersJson.edges;
    const { slider, sliderStyle,sectionTitleStyle } = props
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title="Our Clients"
                            subtitle="We help our clients launch the best business"
                        >
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {/* <SwiperSlider settings={slider} {...sliderStyle}> */}
                            {/* {partnersData.map((data, i) => {
                                return (
                                    <ClientLogoWrap key={data.node.id}>
                                        <ClientLogo
                                            layout={1}
                                            title={data.node.id}
                                            path={data.node.path}
                                            brandImage={data.node.image.childImageSharp}
                                            hoverImage={data.node.hover_image.childImageSharp}
                                        />
                                    </ClientLogoWrap>
                                )
                            })} */}
                             {/* <div>
                        <img src="../../../../../scm.png" alt="scm"></img>
                        </div>
                        <div>
                        <img src="../../../../../precursor.png" alt="precursor"></img>
                        </div>
                        <div>
                        <img src="../../../../../emed.png" alt="emed"></img>
                        </div>
                        <div>
                        <img src="../../../../../suissebase.png" alt="suissebase"></img>
                        </div>
                        <div>
                        <img src="../../../../../blackbuck.png" alt="blackbuck"></img>
                        </div>
                        <div>
                        <img src="../../../../../bakertilly.png" alt="bakertilly"></img>
                        </div>
                        <div>
                        <img src="../../../../../mcdominas.png" alt="mcdominas"></img>
                        </div>
                        </SwiperSlider> */}
                        <div className='client-style'>
                            <div className='mr-ml'>
                        <img src="../../../../../scm.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../precursor.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../emed.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../suissebase.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../blackbuck.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../bakertilly.png" alt="digital-transformation"></img>
                        </div>
                        <div  className='mr-ml'>
                        <img src="../../../../../mcdominas.png" alt="digital-transformation"></img>
                        </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

ClientsArea.propTypes = {
    slider: PropTypes.object,
    sliderStyle: PropTypes.object
}

ClientsArea.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    slider: {
        slidesPerView: 4,
        loop: false,
        speed: 1000,
        pagination: false,
        autoplay: {
            delay: 2500
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            575: {
                slidesPerView: 1
            },
            767: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 4
            },
            1499: {
                slidesPerView: 4
            }
        }
    },
    sliderStyle: {
        align: 'center'
    }
}

export default ClientsArea;