import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Anchor from '../../../components/ui/anchor'
import ServiceBox from '../../../components/box-image/layout-six'
import { ServicesWrapper, DesignTool } from './services-area.style'

const Services = ({ serviceBoxStyle, linkStyle, headingStyle }) => {
    const featuredDataQuery = useStaticQuery(graphql`
        query AboutServicesQueryData {
            allItServicesJson(sort: {order: DESC, fields: id}, filter: {is_featured: {eq: true}}, limit: 3) {
                edges {
                  node {
                    fields {
                        slug
                    }
                    id
                    title
                    excerpt
                    icon {
                      img{
                        childImageSharp{
                            fixed(width:100, height: 108, quality: 100 ){
                                ...GatsbyImageSharpFixed_tracedSVG
                            }
                        }
                      }
                    }
                  }
                }
            }
        }
    `);
    const services = featuredDataQuery.allItServicesJson.edges;
    return (
        <ServicesWrapper >
            <Container>
                <Row className="mb-4 rowspace ">
                    <Col lg={5} className="col-pt">
                        <h3 className="mb-2">Our Great <span>Process</span></h3>
                        <p >“Our process involves Imagine, Design, Develop, Launch and Support.”</p>
                        <p>We limit our concurrent Products to ensure your company gets the most care and attention possible.</p>
                    </Col>
                    <Col lg={7}>
                            <Row className="my-4">
                            <Col md={6} className="arrow1">
                                    <DesignTool>
                                    <img src={"../../../../design.svg"} ></img>
                                    <h3>Design</h3>
                                    <span></span>
                                    </DesignTool>
                                </Col>
                                <Col md={6} className="arrow2">
                                    <DesignTool  >
                                <img src={"../../../../development.svg"}></img>
                                <h3> Develop</h3>
                                <span></span>
                                </DesignTool>
                                </Col>
                                <Col md={6} className="arrow4">
                                <DesignTool  >
                                <img src={"../../../../maintainance.svg"}></img>
                                <h3>Maintain</h3>
                                <span></span>
                                    </DesignTool>
                                </Col>
                                <Col md={6} className="arrow3">
                                <DesignTool >
                                <img src={"../../../../deploy.svg"}></img>
                                <h3>Depoly</h3>
                                <span></span>
                                    </DesignTool>
                                </Col>
                                
                            </Row>
                    </Col>
                </Row>
            </Container>
        </ServicesWrapper>
    )
}

Services.propTypes = {
    serviceBoxStyle: PropTypes.object,
    linkStyle: PropTypes.object,
    headingStyle: PropTypes.object,
}

Services.defaultProps = {
    serviceBoxStyle: {
        mb: "50px"
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    headingStyle: {
        as: 'h2',
        fontSize: '24px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#17305a',
        mt: '10px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '5px'
            }
        }
    }
}

export default Services;